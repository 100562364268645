<template>
  <div class="links-contain">
    <div class="links-title">院系友情链接</div>
    <div class="links-item">
      <div class="links-item-item">
        <div class="links-item-item-title">植物科学技术学院</div>
        <el-image class="links-item-item-icon" :src="moreicon" />
      </div>

      <div class="links-item-item margin_left">
        <div class="links-item-item-title">植物科学技术学院</div>
        <el-image class="links-item-item-icon" :src="moreicon" />
      </div>

      <div class="links-item-item margin_left">
        <div class="links-item-item-title">植物科学技术学院</div>
        <el-image class="links-item-item-icon" :src="moreicon" />
      </div>

      <div class="links-item-item margin_left">
        <div class="links-item-item-title">植物科学技术学院</div>
        <el-image class="links-item-item-icon" :src="moreicon" />
      </div>

      <div class="links-item-item margin_left">
        <div class="links-item-item-title">植物科学技术学院</div>
        <el-image class="links-item-item-icon" :src="moreicon" />
      </div>
    </div>

    <div class="links-item">
      <div class="links-item-item">
        <div class="links-item-item-title">植物科学技术学院</div>
        <el-image class="links-item-item-icon" :src="moreicon" />
      </div>

      <div class="links-item-item margin_left">
        <div class="links-item-item-title">植物科学技术学院</div>
        <el-image class="links-item-item-icon" :src="moreicon" />
      </div>

      <div class="links-item-item margin_left">
        <div class="links-item-item-title">植物科学技术学院</div>
        <el-image class="links-item-item-icon" :src="moreicon" />
      </div>

      <div class="links-item-item margin_left">
        <div class="links-item-item-title">植物科学技术学院</div>
        <el-image class="links-item-item-icon" :src="moreicon" />
      </div>

      <div class="links-item-item margin_left">
        <div class="links-item-item-title">植物科学技术学院</div>
        <el-image class="links-item-item-icon" :src="moreicon" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Carousel",
  components: {},
  data() {
    return {
      moreicon: require("@/assets/icon_link.png"),
    };
  },
  computed: {},
  methods: {},
};
</script>
<style scoped lang="scss">
.links-contain {
  //   border: 1px solid red;
  width: 1760px;
  height: 100%;
  .links-title {
    // border: 1px solid red;
    margin-top: 80px;
    margin-left: 60px;
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #000000;
  }
  .links-item {
    margin-top: 30px;
    margin-left: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .links-item-item {
      display: inline-flex;
      justify-content: flex-start;
      align-items: center;
      .links-item-item-title {
        font-size: 15px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #666666;
        user-select: none;
        white-space: nowrap;
      }
      .links-item-item-icon {
        margin-left: 5px;
        width: 12px;
        height: 12px;
      }
    }
    .margin_left {
      // margin-left: 190px;
    }
  }
}
</style>
