<template>
  <!-- 轮番图模块 -->
  <el-carousel :interval="5000" arrow="always" :height="bannerHeight + 'px'">
    <el-carousel-item v-for="(item, index) in images" :key="index">
      <div class="image-contain" @click="blank_href(item.href)">
        <el-image class="elimage" :src="item.imageitem" />
        <!-- <el-image class="elimage" :src="imagetest" /> -->
      </div>
    </el-carousel-item>
  </el-carousel>
</template>

<script>
import { getBannerList } from "@/api/banner"
export default {
  name: "Carousel",
  components: {},
  data () {
    return {
      imagetest: require("@/assets/new/test.jpg"),
      images: [],
      bannerHeight: 0,
      screenWidth: 0,
    }
  },
  computed: {},
  mounted () {
    this.getDataList({
      type: 1
    })
    // 根据不同路由跳转不同页面
    if (this._isMobile()) {
      // 首次加载时,初始化高度
      this.screenWidth = window.innerWidth
      this.bannerHeight = 0.5 * this.screenWidth
      // 窗口大小发生改变
      window.onresize = () => {
        this.screenWidth = window.innerWidth
        this.bannerHeight = 0.5 * this.screenWidth
      }
    } else {
      // 首次加载时,初始化高度
      this.screenWidth = window.innerWidth
      this.bannerHeight = 400 / 1550 * this.screenWidth
      // 窗口大小发生改变
      window.onresize = () => {
        this.screenWidth = window.innerWidth
        this.bannerHeight = 400 / 1550 * this.screenWidth
      }
    }

  },
  methods: {
    // 判断是否是手机端，如果是，返回true
    _isMobile () {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag
    },

    getDataList (params) {
      this.images = []
      getBannerList(params)
        .then(res => {
          if (res.code === 0 && res.data.length > 0) {
            res.data.forEach((item) => {
              this.images.push({
                imageitem: item.url,
                href: item.href
              })
            })
          }
        })
        .catch((error) => { })
    },
    blank_href (item) {
      console.log(item, "href")
      window.open(item, '_blank')
    }
  },
};
</script>
<style scoped lang="scss">
.image-contain {
  width: 100%;
  height: 100%;
  cursor: pointer;

  .elimage {
    width: 100%;
    height: inherit;
  }
}
</style>
