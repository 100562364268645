import request from '@/utils/request'

//获取实验室使用排行
export function getlabrank(params) {
    return request({
        url: '/portal/lab/lab-rank',
        method: 'GET',
        params
    })
}

//获取虚拟仿真排行
export function getvrrank(params) {
    return request({
        url: '/portal/vr/vr-rank',
        method: 'GET',
        params
    })
}

//获取课程排行
export function getcourserank(params) {
    return request({
        url: '/portal/course/course-rank',
        method: 'GET',
        params
    })
}

//获取qa排行
export function getqarank(params) {
    return request({
        url: '/portal/qa/qa-rank',
        method: 'GET',
        params
    })
}