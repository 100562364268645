<template>
  <div class="virtualsimulationexperiment">
    <div class="head-contain">
      <div class="head-contain-left"></div>
      <div class="head-contain-center">
        <div class="title">
          <div class="title-icon">
            <el-image class="elimage" :src="image" />
          </div>
          <div class="title-text">开放实验</div>
        </div>
      </div>
      <div class="head-contain-right">
        <div class="more-contain">
          <div class="more" @click="tomore">
            <div class="more-title" >更多</div>
            <div class="more-icon">
              <el-image class="elimage" :src="moreicon" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="content-item" @click="todetail">
        <div class="image-contain">
          <el-image class="elimage" :src="testimage" />
        </div>
        <div class="title1">【毕业季】“钢铁长龙”毕业巡游欢乐举行</div>
        <div class="title2">周悦娜 华中农业大学植物科学技术学院</div>
      </div>
      <div class="content-item marginleft">
        <div class="image-contain">
          <el-image class="elimage" :src="testimage" />
        </div>
        <div class="title1">【毕业季】“钢铁长龙”毕业巡游欢乐举行</div>
        <div class="title2">周悦娜 华中农业大学植物科学技术学院</div>
      </div>
      <div class="content-item marginleft">
        <div class="image-contain">
          <el-image class="elimage" :src="testimage" />
        </div>
        <div class="title1">【毕业季】“钢铁长龙”毕业巡游欢乐举行</div>
        <div class="title2">周悦娜 华中农业大学植物科学技术学院</div>
      </div>
      <div class="content-item marginleft">
        <div class="image-contain">
          <el-image class="elimage" :src="testimage" />
        </div>
        <div class="title1">【毕业季】“钢铁长龙”毕业巡游欢乐举行</div>
        <div class="title2">周悦娜 华中农业大学植物科学技术学院</div>
      </div>
      <div class="content-item marginleft">
        <div class="image-contain">
          <el-image class="elimage" :src="testimage" />
        </div>
        <div class="title1">【毕业季】“钢铁长龙”毕业巡游欢乐举行</div>
        <div class="title2">周悦娜 华中农业大学植物科学技术学院</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Virtualsimulationexperiment",
  components: {},
  data() {
    return {
      image: require("@/assets/shiyan-02.png"),
      moreicon: require("@/assets/组 13.png"),
      testimage: require("@/assets/test.png"),
    };
  },
  computed: {},
  methods: {
    tomore() {
      window.localStorage.setItem("menuId", 4);
      this.$store.dispatch("setmenuid", 4);
      this.$router.push({
        path: "/openexperiment/mainpage",
      });
    },

    todetail() {
      window.localStorage.setItem("menuId", 4);
      this.$store.dispatch("setmenuid", 4);
      this.$router.push({
        path: "/openexperiment/experimentdetails",
      });
    },
  },
};
</script>
<style scoped lang="scss">
.virtualsimulationexperiment {
  //   border: 1px solid red;
  padding-left: 50px;
  padding-right: 50px;
  background: #f7f7f7;
  width: 1760px;
  height: 100%;
  .head-contain {
    // border: 1px solid red;
    margin-top: 10px;
    margin-bottom: 10px;
    padding-left: 60px;
    height: 28px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // margin-top: 10px;
    margin-bottom: 10px;
    .head-contain-left {
      // border: 1px solid red;
      height: 100%;
      width: 64px;
    }
    .head-contain-center {
      // border: 1px solid red;
      height: 100%;
      //   width: 130px;
      .title {
        // border: 1px solid red;
        // margin-top: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        .title-icon {
          width: 29px;
          height: 24px;
        }
        .title-text {
          margin-left: 10px;
          font-size: 25px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #000000;
        }
      }
    }
    .head-contain-right {
      //   border: 1px solid red;
      height: 100%;
      //   width: 100px;
      .more-contain {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .more {
          width: 64px;
          height: 28px;
          background: #ffffff;
          border: 1px solid #ededed;
          border-radius: 14px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          user-select: none;
          cursor: pointer;
          .more-title {
            margin-left: 10px;
            font-size: 12px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #666666;
          }
          .more-icon {
            margin-right: 10px;
            .elimage {
              width: 12px;
              height: 12px;
            }
          }
        }
      }
    }
  }
  .content {
    // border: 1px solid rgb(0, 255, 0);
    height: 365px;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .content-item {
      border: 1px solid transparent;
      height: 100%;
      //   width: 330px;
      flex-grow: 1;
      flex-shrink: 1;
      cursor: pointer;
      &:hover {
        border: 1px solid rgb(9, 235, 178);
      }
      .image-contain {
        width: 100%;
        height: 260px;

        .elimage {
          width: 100%;
          height: 100%;
        }
      }
      .title1 {
        margin-top: 10px;
        text-align: center;
        font-size: 15px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #222222;
      }
      .title2 {
        margin-top: 10px;
        text-align: center;
        font-size: 15px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #666666;
      }
    }
    .marginleft {
      margin-left: 20px;
    }
  }
}
</style>
